@font-face {
  font-family: 'Saans TRIAL Medium';
  src: url('./fonts/SaansTRIAL-Medium.ttf');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0px !important;
  background-color: aliceblue !important;
  margin: 0px !important;
  padding: 0px !important; 
  position: relative;
  height: 120vh;
  align-items: center;
  font-family: "Saans TRIAL Medium" !important;
  box-sizing: border-box !important;
  overflow-x: hidden !important;

}

.blob {
  position: absolute;
  top: 0;
  left: 0;
  width: 55vmax;
  z-index: -1;
  animation: move 60s ease-in-out infinite;
  transform-origin: 50% 50%;
}

@keyframes move {
  0%   { transform: scale(1)   translate(10px, -30px); }
  38%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  40%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  78%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  80%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  100% { transform: scale(1)   translate(10px, -30px); }
}

.header{
  padding: 20px 0px;
  margin: 30px 90px; 
}

.landpage{
  height: 500px !important;
  padding: 0px 50px; 
}

.hero-text{
  font-size: 40px !important;
  margin-bottom: 0px !important; 
}

.hero-text-incentive{
  font-size: 30px !important;
  margin-bottom: 0px !important; 
  padding: 20px 20px 10px 20px !important; 
  width: 100% !important;
  font-weight: 700 !important;
  color: #06205b !important;
}

.footer-space{
  margin-top:50px !important; 
}

.landpage-image{
  height: 47% !important;
  margin: 10px 10% !important;
  margin-top: -20px !important;
}

.hero-sub-text{
  font-size: 24px !important;
  margin-top: 50px !important;
  color: #000000; 
  font-weight: 600;
  line-height: 50px !important;
}

.tech{
  font-size:37px !important;
  font-weight:700;
  color: #06205b;

}

.accent{
  /* background-color: #06205b !important;
  padding:0px 7px !important;
  color:#fff; */
}

@property --bg-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: "<angle>";
}

@keyframes spin {
  to {
    --bg-angle: 360deg;
  }
}


.questionnaire-button{
  background-color: #06205b !important;
  color:#fff !important;
  height:55px;
  margin-top:6px !important;
  border-radius: 50px !important;
}

.questionnaire-button:hover{
  border: 1px solid #06205b !important;
  background-color: rgb(217,227,247) !important;
  color: #06205b !important;
  text-align: #06205b;
}

.landing-quesionnaire-button{
  margin-top:36px !important;
  text-align: center !important;
  font-size: 14px !important;
}

.phoneNumber{
  color: rgb(46,56,71) !important;
}

.lift{
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}

.whatsapp{
  position: fixed !important;
  bottom:100px !important;
  right:30px !important;
  background-color: #fff !important;
  width:60px !important;
  height:60px !important;
  border-radius:50%;
  text-align: center !important;
  padding: 5px;
  z-index:3;
}

.copyright{
  color:#06205b !important;
}

.hero-content{
  padding: 0px 20px 20px 20px;
}

.final-cta-button{
  margin-top:0px !important;
}

.final-cta-text{
  padding:0px 400px 50px 400px !important; 
}


.footer{
  padding: 30px 0px;
  margin: 0px 30px;
  margin-top: 30px;
  justify-content:center;
  text-align:center;
}

.footer-text{
  color: #7f8082;
}

.image-size{
  height: 400px !important;
  width: 100% !important;
}

.incentives{
  padding:50px 70px; 
  padding-top:0px !important;
  padding-bottom:30px !important;
  font-weight: 500;
  color: rgb(46,56,71);
}

.Yourvoice{
  color: #06205b !important;
  font-size: 30px !important;
  margin-bottom: 0px !important; 
  padding: 20px 20px 10px 20px !important; 
  width: 100% !important;
  font-weight: 700 !important;
}

.feature-logo{
  height:80px !important;
  width:80px !important;
  margin-bottom: 30px;
  padding:20px !important;
  border-radius:50% !important; 
  border: solid 1px #06205b;
}

.hero-sub-text-incentive{
  padding: 0px 20px !important;
  font-style: italic !important;
  font-size:14px !important;
  font-weight:700 !important;
}

.contact-box{
  padding-left: 350px !important;
}

.contact-box2{
  padding-right: 350px !important;
}

.contact{
  padding: 25px !important;
  margin-top:40px;
  height: 300px;
  background-image: url('./images/makeupbg.jpg');
  background-size: cover;
  background-position: center; 
  color: rgb(46,56,71) !important; 
}

.feature-container{
  text-align: center !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}


/* Phone Portrait */
@media screen and (max-width: 480px) {
  .mobile-width{
    width: 100% !important;
  }

  .landpage{
    height: 900px !important;
    padding: 0px 0px !important; 
  }

  .hero-sub-text-incentive{
    text-align:center;
  }

  .final-cta-text{
    padding:50px !important; 
  }

  .hero-content{
    padding: 8px;
  }

  .header{
    margin: 20px 30px 0px 30px !important ;
  }

  .final-cta-text{
    /* padding:50px !important;  */
    text-align: center;
  }

  .footer{
    margin: 0px 30px;
    margin-top:10px;
  }

  .landpage-image{
    height: 32% !important;
    margin-right:20px !important;
  }

  .hero-text{
    font-size: 40px !important;
    width: 100% !important;
    text-align:center;
  }

  .hero-text-incentive{
    font-size: 30px !important;
    text-align:center;
    padding: 20px;
    padding-top: 20px !important;
    width: 100% !important;
  }

  .whatsapp{
    position: fixed !important;
    bottom:120px !important;
    right:10px !important;
    background-color: #fff !important;
    width:60px !important;
    height:60px !important;
    border-radius:50%;
    text-align: center !important;
    padding: 5px;
    z-index:3;
  }

  .contact-box{
    padding: 0px !important;
  }
  
  .contact-box2{
    padding: 0px !important;
  }
  
 .mobile-contact{
   margin-right:15px !important;
   margin-bottom: 40px !important;
   display: inline !important;
 }

  .feature-logo{
    text-align: center !important;
    margin-top: 30px !important;
  }

  .feature-container{
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important; 
  }

  .hero-sub-text{
    font-size: 20px !important;
    text-align:center;
    margin-top: 0px !important;
  }


  .questionnaire-button{
    margin-left:8px !important;
  } 

  .landing-quesionnaire-button{
    margin-top:36px !important;
    margin-bottom:36px !important;
  }

  .final-cta-button{
    margin-top:0px !important;
  }

  .incentives{
    /* background-color: #fff; */
    padding:30px;
    width:110% !important;
  } 


  .blob {
    position: absolute;
    top: 0;
    left: 0;
    width: 45vmax;
    z-index: -1;
    animation: move 180s ease-in-out infinite;
    transform-origin: 50% 0%;
  }

  .header{
    text-align: center !important;
  }
}

/* Phone Landscape */
@media only screen and (-webkit-min-device-pixel-ratio: 2.5) and (orientation: landscape) {

  .mobile-width{
    width: 100% !important;
  } 

  .landpage{
    height: 680px !important;
    padding: 0px 0px !important; 
  }

  .hero-sub-text-incentive{
    text-align:center;
  }

  .final-cta-text{
    padding:0px 0px 0px 30px !important; 
    text-align: center;
    margin-bottom: 40px !important;
  }

  .hero-content{
    padding: 8px;
  }

  .header{
    margin: 20px 30px 0px 30px !important ;
  } 
  .footer{
    margin: 0px 30px;
    margin-top:10px;
  }

  .landpage-image{
    height: 36% !important;
    margin:20px 22% !important;
  }

  .hero-text{
    font-size: 40px !important;
    width: 100% !important;
    text-align:center;
  }

  .hero-text-incentive{
    font-size: 30px !important;
    text-align:center;
    padding: 20px;
    padding-top: 20px !important;
    width: 100% !important;
  }

  .whatsapp{
    position: fixed !important;
    bottom:120px !important;
    right:10px !important;
    background-color: #fff !important;
    width:60px !important;
    height:60px !important;
    border-radius:50%;
    text-align: center !important;
    padding: 5px;
    z-index:3;
  }

  .contact-box{
    padding: 0px !important;
  }
  
  .contact-box2{
    padding: 0px !important;
  }
  
 .mobile-contact{
   margin-right:15px !important;
   margin-bottom: 40px !important;
   display: inline !important;
 }

  .feature-logo{
    text-align: center !important;
    margin-top: 30px !important;
  }

  .feature-container{
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important; 
  }

  .hero-sub-text{
    font-size: 20px !important;
    text-align:center;
    margin-top: 0px !important;
  }


  .questionnaire-button{
    margin-left:8px !important;
  } 

  .landing-quesionnaire-button{
    margin-top:36px !important;
    margin-bottom:36px !important;
  }

  .final-cta-button{
    margin-top:0px !important;
  }

  .incentives{
    padding:30px;
    width:110% !important;
  } 


  .blob {
    position: absolute;
    top: 0;
    left: 0;
    width: 45vmax;
    z-index: -1;
    animation: move 180s ease-in-out infinite;
    transform-origin: 50% 0%;
  }

  .header{
    text-align: center !important;
  }
}

/* iPad Mini*/
@media (min-width: 768px) and (max-width: 1024px) {
  .landpage-image{
    height: 42% !important;
    margin-top: 40px !important;
  }

  .final-cta-text{
    padding:0px 150px 50px 150px !important; 
  }

  .hero-text{
    font-size: 27px !important;
    margin-bottom: 0px !important; 
  }

  .hero-sub-text{
    font-size: 19px !important;
    margin-top: 16px !important;
    color: #536368;
    /* line-height: 23px !important; */
  }

  .contact-box{
    padding-left: 100px !important;
  }
  
  .contact-box2{
    padding-right:100px !important;
  }

  .incentives{
    width:125% !important
  }

  .landpage{
    height: 500px !important;
  }

  .divider{
    width:119% !important
  }

  .final-cta{
    width:124% !important
  }

  .hero-text-incentive{
    font-size: 25px !important;
    /* background-color:#000000 !important;  */
    /* overflow: auto; */
  }

  .contact{
    width:124% !important

  }
  .socials{
    width: 125% !important;
  }
}

/* Other ipads */
@media (min-width: 820px) and (max-width: 1180px) {
  .landpage-image{
    height: 28% !important;
  }

  .hero-text{
    font-size: 27px !important;
    margin-bottom: 0px !important; 
  }

  .hero-sub-text{
    font-size: 17px !important;
    margin-top: 16px !important;
    color: #536368;
  }

  .hero-text-incentive{
    font-size: 25px !important;
  }

  .incentives{
    width:105% !important
  }

  .contact-box{
    padding-left: 100px !important;
  }
  
  .contact-box2{
    padding-right:100px !important;
  }

  .landpage{
    height: 500px !important;
  }

  .divider{
    width:100% !important
  }

  .hero-text-incentive{
    font-size: 22px !important; 
  }

  .final-cta, .contact{
    width:104% !important
  }

  .socials{
    width: 100% !important;
    /* background-color: red; */
  }
}
